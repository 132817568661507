import 'aos/dist/aos.css';
import 'react-modal-video/css/modal-video.min.css';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import 'tailwindcss/dist/base.min.css';
import 'tailwindcss/dist/utilities.min.css';
import 'tailwindcss/dist/components.min.css';
import './src/styles/global.css';

import AOS from 'aos';
AOS.init();

const bait = `


    .oooooo.     .oooooo.   oooooo     oooo oooooo     oooo oooooooooooo
    d8P'  \`Y8b   d8P'  \`Y8b   \`888.     .8'   \`888.     .8'  \`888'     \`8
   888          888      888   \`888.   .8'     \`888.   .8'    888
   888          888      888    \`888. .8'       \`888. .8'     888oooo8
   888          888      888     \`888.8'         \`888.8'      888    "
   \`88b    ooo  \`88b    d88'      \`888'           \`888'       888       o
    \`Y8bood8P'   \`Y8bood8P'        \`8'             \`8'       o888ooooood8

   Like what you see, join us!
   https://covve.com/careers/


`;

export const onClientEntry = () => {
  console.log(`%c ${bait}`, 'color: #36f');
};

export const onRouteUpdate = function () {
  // Don't track while developing.
  if (process.env.NODE_ENV === `production` && typeof fbq === `function`) {
    fbq('track', 'ViewContent');
  }
};

export const disableCorePrefetching = () => true;
